<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title :style="isMobile ? 'font-size: 1.25rem;' : 'font-size: 1.5rem;'">
        Attachments&nbsp;
        <span v-if="session">- {{ session.label }}</span>
      </va-card-title>
      <va-card-content v-if="session">
        <div class="row">
          <va-input
            class="flex mb-2 md6 xs9"
            placeholder="Filter..."
            v-model="filterText"
            v-on:keyup.enter="addFilter"
          />
          <div class="flex mb-2 md3 xs3 text-left" style="padding-right: 4rem;" v-if="filterText.length > 0">
            <va-icon :size="'2rem'" :name="'fa4-plus-square'" class="link" @click="addFilter" />
          </div>
          <div class="flex mb-2 md3 xs12" :class="isMobile ? 'text-center' : 'text-right'" :style="isMobile ? 'padding-right: 4rem;' : ''" v-if="filterText.length > 0 && selectedItems.length > 0">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" style="padding-left: 1rem;" @click="visualizeSessions" v-if="session.mode === 'Telemetry Mode'" />
          </div>
          <div class="flex mb-2 md6 xs12" :class="isMobile ? 'text-center' : 'text-right'" :style="isMobile ? 'padding-right: 4rem;' : ''" v-else-if="filterText.length <= 0 && selectedItems.length > 0">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" style="padding-left: 1rem;" @click="visualizeSessions" v-if="session.mode === 'Telemetry Mode'" />
          </div>
        </div>
        <div class="row">
          <div class="flex xs12">
            <va-chip outline class="mb-2 mr-2" color="primary" v-for="(filter, i) in filters" v-bind:key="`chip${i}`">
              {{ filter }}&nbsp;<va-icon :size="'1rem'" :name="'fa4-remove'" style="cursor: pointer;" @click="removeFilter(i)" />
            </va-chip>
          </div>
        </div>
        <va-data-table
          :items="filtered"
          :columns="columns"
          :per-page="perPage"
          :current-page="currentPage"
          :selectable="true"
          v-model="selectedItems"
        >
          <template #bodyAppend>
            <tr>
              <td colspan="5" class="table-example--pagination">
                <va-pagination
                  v-model="currentPage"
                  input
                  :pages="pages"
                />
              </td>
              <td colspan="5" class="table-example--pagination">
                <va-select
                  style="width: 8rem;"
                  v-model="perPage"
                  :label="'Per Page'"
                  :options="perPageOptions"
                  noClear
                />
              </td>
            </tr>
          </template>
          <template #cell(uid)="{ source: uid }">
            <router-link :key="uid" :to="{ name: 'attachment', params: { id, attachmentId: uid }}">
              <va-icon :name="'fa4-eye'"/>
            </router-link>
            <va-icon :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeModal(uid, label)" />
          </template>
        </va-data-table>
        <va-alert class="mt-3" border="left" v-if="filters.length > 0">
          <span>
            Number of filtered items:
            <va-chip>{{ filteredCount }}</va-chip>
          </span>
        </va-alert>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="removeModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove an attachment</h2>
      </template>
      <slot>
        <div>Do you want to remove {{ removeName }}?</div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeModalConfirm">Delete</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="removeMultiModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove attachments</h2>
      </template>
      <slot>
        <div>Do you want to remove the following attachments?</div>
        <br />
        <p v-for="removeMultiName in removeMultiNames">{{ removeMultiName }}</p>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeMultiModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeMultiModalConfirm">Delete All</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VueApexCharts from 'vue3-apexcharts'
import { isMobile } from 'mobile-device-detect'

export default {
  components: {
    'apexchart': VueApexCharts
  },
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load session
      const session = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}`, { headers })
      this.session = session.data.session

      // Load a list of attachments
      const attachments = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/attachments?sort_by=-timestamp`, { headers })
      this.attachments = await Promise.all(attachments.data.attachments.map((attachment) => {
        attachment.created_at = moment(attachment.created_at).format('YYYY-MM-DD hh:mm a')
        attachment.timestamp = moment.utc(attachment.timestamp).format('YYYY-MM-DD hh:mm a')
        this.attachmentsObject[attachment.uid] = attachment.name
        return attachment
      }))
      this.attachments.sort((a,b) => {
        return new Date(b.timestamp) - new Date(a.timestamp)
      })
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/attachments` } })
    }
    if (this.isMobile) {
      this.columns = [
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        },
        {
          key: 'name',
          label: 'Attachments',
          headerTitle: 'Attachments',
          sortable: true
        },
        {
          key: 'type',
          label: 'Type',
          headerTitle: 'Type',
          sortable: true
        },
        {
          key: 'timestamp',
          label: 'Captured At',
          headerTitle: 'Captured At',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        }
      ]
    }
    this.loader.hide()
  },
  data () {
    return {
      isMobile,
      loader: null,
      token: this.$store.state.token,
      id: this.$route.params.id,
      session: null,
      attachments: [],
      attachmentsObject: {},
      selectedItems: [],
      selectedAttachments: [],
      columns: [
        {
          key: 'name',
          label: 'Attachments',
          headerTitle: 'Attachments',
          sortable: true
        },
        {
          key: 'type',
          label: 'Type',
          headerTitle: 'Type',
          sortable: true
        },
        {
          key: 'timestamp',
          label: 'Captured At',
          headerTitle: 'Captured At',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ],
      filterText: '',
      filters: [],
      perPage: 10,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,
      removeModalShow: false,
      removeId: null,
      removeName: null,
      removeMultiModalShow: false,
      removeMultiNames: []
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length
    },
    filtered () {
      if (!this.filters || this.filters.length < 1) {
        return this.attachments
      }

      let tempFiltered = this.attachments
      this.filters.forEach((filter) => {
        if (filter.trim().length > 0) {
          tempFiltered = tempFiltered.filter(attachment => {
            return (attachment.name ? attachment.name.toLowerCase().includes(filter.trim().toLowerCase()) : false) ||
                   (attachment.type ? attachment.type.toLowerCase().includes(filter.trim().toLowerCase()) : false) ||
                   (attachment.created_at ? attachment.created_at.toLowerCase().includes(filter.trim().toLowerCase()) : false)
          })
        }
      })
      return tempFiltered
    },
    filteredCount () {
      return this.filtered.length
    }
  },
  methods: {
    removeModal (id) {
      this.removeId = id
      this.removeName = this.attachmentsObject[id]
      this.removeModalShow = true
    },
    removeModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Delete a attachment
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/attachments/${this.removeId}`, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'attachment deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load a list of attachment again
        const attachments = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/attachments?sort_by=-timestamp`, { headers })
        this.attachments = await Promise.all(attachments.data.attachments.map((attachment) => {
          attachment.created_at = moment(attachment.created_at).format('YYYY-MM-DD hh:mm a')
          return attachment
        }))

        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
      } catch (error) {
        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/attachments` } })
      }
      this.loader.hide()
    },
    removeMultiModal: async function () {
      this.removeMultiNames = await Promise.all(this.selectedItems.map((selectedItem) => selectedItem.name))
      this.removeMultiModalShow = true
    },
    removeMultiModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        // Delete sessions
        await Promise.all(this.selectedItems.map((selectedItem) => this.removeItem(selectedItem.uid)))
        this.$vaToast.init({
          message: 'Attachments deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load a list of attachment again
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const attachments = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/attachments?sort_by=-timestamp`, { headers })
        this.attachments = await Promise.all(attachments.data.attachments.map((attachment) => {
          attachment.created_at = moment(attachment.created_at).format('YYYY-MM-DD hh:mm a')
          return attachment
        }))

        // Load a list of session again
        this.selectedItems = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
      } catch (error) {
        this.selectedItems = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/attachments` } })
      }
      this.loader.hide()
    },
    removeItem (id) {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      return axios.delete(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/attachments/${id}`, { headers })
    },
    addFilter: function () {
      if (this.filterText && this.filterText.length > 0) {
        this.filters.push(this.filterText.trim())
        this.filterText = ''
      }
    },
    removeFilter: function (index) {
      this.filters.splice(index, 1)
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: right;
    text-align: -webkit-right;
    padding-top: 1rem;
  }

  .link {
     cursor: pointer;
     color: var(--va-primary);
  }

  .va-data-table {
    --va-data-table-thead-color: var(--va-primary);
  }

  .va-pagination__input {
    background-color: var(--va-white);
  }

  .va-alert {
    background-color: var(--va-white) !important;;
  }

  .vue-map > div > div > div > div > div > div > div > div > div > div > div {
    color: var(--va-pure-dark) !important;
  }
</style>
